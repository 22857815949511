import { Injectable } from "@angular/core";
import { BaseService } from "../../core/classes/base.service";
import { Account, Affiliate } from "../classes/accountaffiliate";

@Injectable()
export class AppUserService extends BaseService {

    private accounts: Account[];

    private affiliates: Affiliate[] = [];

    private defaultaffiliate: Affiliate;

    private showprimaryaffliatesonly: boolean = true;

    private hasallhospitalsasprimary: boolean = true;

    constructor() {
        super();
    }

    public setShowPrimaryAffiliates(showall: boolean) {
        this.showprimaryaffliatesonly = showall;
    }

    public getShowPrimaryAffiliates(): boolean {
        return this.showprimaryaffliatesonly;
    }

    public getAffiliates() {
        return !this.showprimaryaffliatesonly ? this.affiliates : this.affiliates.filter(x => x.is_primary);
    }

    public getAccounts() {
        return !this.showprimaryaffliatesonly ? this.accounts : this.accounts.filter(x => x.is_primary);
    }

    public setAffiliateData(affiliates: Affiliate[], defaultaffiliateid: number, hasallhospitalsasprimary: boolean) {
        this.affiliates = [];
        this.hasallhospitalsasprimary = hasallhospitalsasprimary;
        affiliates.forEach(x => this.affiliates.push(x));
        this.affiliates.forEach(x => x.is_default = x.affiliate_id === defaultaffiliateid);
        let obj = this.affiliates.find(x => x.is_default);
        if (obj) {
            this.defaultaffiliate = obj;
        }
        else {
            this.defaultaffiliate = this.affiliates[0];
            this.defaultaffiliate.is_default = true;
        }

        affiliates.forEach(x => x.is_primary = x.is_primary.toString() == "1");//todo remove once adil removes
    }

    getAllAffiliates(): Affiliate[] {
        return this.affiliates;
    }

    public setLoginData(data: any): void {
        this.setAffiliateData(data.defaults.hospitals, data.defaults.default_hospital.affiliate_id, data.defaults.has_all_hospitals_as_primary);
    }
}


// export class PrimaryAffiliateSettings {
//     affiliates: Affiliate[];

//     defaultaffiliateid: string;
// }

// export class Affiliate {
//     affiliate_id: string;

//     affiliate_name: string;

//     account_id: string;

//     account_name: string;

//     is_primary: boolean;

//     is_default: boolean;
// }